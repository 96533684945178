import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { useRoute } from 'vue-router'
import MembersDataService from '@/services/MembersDataService'
import UsersDataService from '@/services/UsersDataService'
import { Products } from '@/services/ProductsDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { History } from '@/services/HistoryDataService'
import ClubChangeDataService from '@/services/ClubChangeDataService'
import SysClubChange, { SysClubChangeFriendlyType } from '@/types/SysClubChange'
import SysClub from '@/types/SysClub'
import SysHistory from '@/types/SysHistory'
import SysMember, { ClubRoleType } from '@/types/SysMember'
import SysProduct from '@/types/SysProduct'
import SysCart from '@/types/SysCart'
import CommonFunctions, { stringToEnumValue, defaultLicenseId, defaultJuniorLicenseId } from '@/components/Utility/Common'
import BaseFunctions, { defaultClubId, defaultClubCaptainGroupId, defaultClubManagerGroupId, defaultSuperAdminGroupId, defaultAdminBackendGroupId } from '@/components/Utility/Base'
import AuthService from '@/services/AuthService'
import ByPostDataService from '@/services/ByPostDataService'

type memberDataType = { memberId: number; firstName: string; lastName: string; userName: string; telephone: string; email: string; address: string; postalNumber: string; city: string; paraDartPlayer: boolean; licenseExpires: string; licenseNumber: number, quarantineStartDate: string, quarantineEndDate: string, birthDate: string; gender: string; lastLogin: string; teamCaptain: boolean; lastMatch: string; club: string; permissions: number; userId: number }
type dataReturnType = { clubMembers: any; licenseCheckAll: boolean, checked: SysMember[], memberData: memberDataType, memberHistory: SysHistory[], memberSearchInputValue: string; memberSearchTerm: string, editMemberMailAddressModal: boolean, newMemberMailAddress: string }

export default class clubMembers extends Vue {
  private todaysDate = new Date().toISOString().split('T')[0]
  // private todaysDate = '2023-11-30T08:00:30'
  private seasonHalfWayPoint = new Date(this.todaysDate).getFullYear() + '-07-01'
  private seasonEndPoint = new Date(this.todaysDate).getFullYear() + '-12-01'
  private licenseProduct = {} as SysProduct
  private juniorLicenseProduct = {} as SysProduct
  private renewLicensesModal = false
  private clubManagerId = this.parseURL()
  private clubMembers: SysMember[] = []
  private clubId = 0
  private currentListSortingOrder = 'user_id.lastname:asc'
  private memberData: memberDataType = { memberId: 0, firstName: '', lastName: '', userName: '', telephone: '', email: '', address: '', postalNumber: '', city: '', paraDartPlayer: false, licenseExpires: '', licenseNumber: 0, quarantineStartDate: '', quarantineEndDate: '', birthDate: '', gender: '', lastLogin: '', teamCaptain: false, lastMatch: '', club: '', permissions: 0, userId: 0 }
  memberSearchInputValue = ''
  memberSearchTerm = ''
  licenseCheckAll = false
  checked: SysMember[] = []
  private releaseMemberId = 0
  private releaseMemberModal = false
  private extraMemberInfoModal = false
  private clubRoleStringValue = ''
  private clubRoleOptions: string[] = []
  private memberHistory: SysHistory[] = []
  private showCompleteHistory = false
  private originalSort = ''
  private memberStatistics: SysMember [] = []
  private memberStatisticsModal = false
  private editMemberMailAddressModal = false
  private newMemberMailAddress = ''
  private mailUpdateUserId = 0
  statisticsOptions: string[] = ['DIF', 'Kommunen']
  selectedStatistics = 'DIF'
  private currentCityPostalNumberID = 0
  editMemberInformation = false
  beforeEditMemberInformation = {
    memberId: this.memberData.memberId,
    telephone: this.memberData.telephone,
    email: this.memberData.email,
    address: this.memberData.address,
    postalNumber: this.memberData.postalNumber,
    city: this.memberData.city
  }

  readonly name : string = 'clubMembers'
  $Message: any
  data (): dataReturnType {
    return {
      clubMembers: this.clubMembers,
      licenseCheckAll: this.licenseCheckAll,
      checked: this.checked,
      memberData: this.memberData,
      memberHistory: this.memberHistory,
      memberSearchInputValue: this.memberSearchInputValue,
      memberSearchTerm: this.memberSearchTerm,
      editMemberMailAddressModal: this.editMemberMailAddressModal,
      newMemberMailAddress: this.newMemberMailAddress
    }
  }

  @Watch('releaseMemberModal')
  onReleaseModalChange (newVal: boolean) : void {
    if (!newVal) {
      this.releaseMemberId = 0
    }
  }

  @Watch('extraMemberInfoModal')
  onExtraInfoModalChange (newVal : boolean) : void {
    if (!newVal) {
      this.memberData = { memberId: 0, firstName: '', lastName: '', userName: '', telephone: '', email: '', address: '', postalNumber: '', city: '', paraDartPlayer: false, licenseExpires: '', licenseNumber: 0, quarantineStartDate: '', quarantineEndDate: '', birthDate: '', gender: '', lastLogin: '', teamCaptain: false, lastMatch: '', club: '', permissions: 0, userId: 0 }
      this.clubRoleStringValue = ''
    }
  }

  @Watch('beforeEditMemberInformation.postalNumber')
  onMemberPostalNumberChange (postalNumberValue: number, oldValue: number) {
    if (!isNaN(postalNumberValue) && postalNumberValue !== oldValue) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(postalNumberValue.toString())
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          const data = response.data
          console.log('fn 2', data)
          if (data === undefined || data === null || data[0] === undefined) {
            this.currentCityPostalNumberID = 0
          } else {
            this.currentCityPostalNumberID = (data[0].id !== null ? Number(data[0].id) : 0)
            this.beforeEditMemberInformation.city = (data[0].id !== null ? (data[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  public editMemberInfo () : void {
    this.editMemberInformation = true
    this.beforeEditMemberInformation = {
      memberId: this.memberData.memberId,
      telephone: this.memberData.telephone,
      email: this.memberData.email,
      address: this.memberData.address,
      postalNumber: this.memberData.postalNumber,
      city: this.memberData.city
    }
  }

  public checkEmail (email: string) : boolean {
    return BaseFunctions.isEmail(email)
  }

  public updateMemberInfo () : void {
    console.log(this.beforeEditMemberInformation)

    const updateMemberInfo = async (userId: number, body: any) => {
      await UsersDataService.update(userId.toString(), body)
        .then((response) => {
          console.log('Success updateMemberInfo: ', response)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const MembersDataInfo = async (memberId: number, body: any) => {
      await MembersDataService.update(memberId.toString(), body)
        .then((response) => {
          console.log('Success MembersDataInfo: ', response)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const promises = []
    if (this.memberData.permissions !== defaultClubManagerGroupId && this.memberData.permissions !== defaultSuperAdminGroupId) {
      const updateUserData = {
        email: this.beforeEditMemberInformation.email,
        phone: this.beforeEditMemberInformation.telephone
      }

      const memberClubRole = this.convertStringToEnum(this.clubRoleStringValue)

      const updateMemberData = {
        medlem_vejnummer: this.beforeEditMemberInformation.address,
        medlem_klubrolle: memberClubRole,
        bypost_id: this.currentCityPostalNumberID
      }

      promises.push(updateMemberInfo(this.memberData.userId, updateUserData))
      promises.push(MembersDataInfo(this.memberData.memberId, updateMemberData))
    }

    Promise.all(promises)
      .then(() => {
        this.$Message.success({ text: 'Medlem opdateret' })
        this.retrieveClubMembers()
        this.extraMemberInfoModal = false
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public membershipStatics () : void {
    MembersDataService.getAll('', null, `klubber_id.id=${this.clubId.toString()}`)
      .then((response) => {
        this.memberStatistics = response.data
        this.memberStatisticsModal = true
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public getMembershipNumber (lowerBound: number, upperBound: number, gender: string) : number {
    let retVal = 0
    switch (gender) {
      case 'male':
        retVal = this.memberStatistics.filter((value) => this.calculateAge(value.medlem_foedselsdag) >= lowerBound && this.calculateAge(value.medlem_foedselsdag) <= upperBound && value.koen_id.id === 2).length
        break
      case 'female':
        retVal = this.memberStatistics.filter((value) => this.calculateAge(value.medlem_foedselsdag) >= lowerBound && this.calculateAge(value.medlem_foedselsdag) <= upperBound && value.koen_id.id === 1).length
        break
      default:
        retVal = this.memberStatistics.filter((value) => this.calculateAge(value.medlem_foedselsdag) >= lowerBound && this.calculateAge(value.medlem_foedselsdag) <= upperBound).length
    }

    return retVal
  }

  public getClubManagers (lowerBound: number, upperBound: number) : number {
    return this.memberStatistics.filter((value) => this.calculateAge(value.medlem_foedselsdag) >= lowerBound && this.calculateAge(value.medlem_foedselsdag) <= upperBound && (value.medlem_klubrolle === ClubRoleType.Traener || value.medlem_klubrolle === ClubRoleType.Formand || value.medlem_klubrolle === ClubRoleType.Kasserer || value.medlem_klubrolle === ClubRoleType.Bestyrelsesmedlem)).length
  }

  public columnSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'user_id.lastname:asc'
      this.originalSort = ''
      this.memberSearchInputValue = ''
      this.memberSearchTerm = ''
    }
    if (sortBy === 'name') {
      if (this.originalSort === '' || this.originalSort !== sortBy) {
        this.currentListSortingOrder = 'user_id.lastname:desc'
        this.originalSort = sortBy
      } else {
        this.currentListSortingOrder = 'user_id.lastname:asc'
        this.originalSort = ''
      }
    }
    if (sortBy === 'license') {
      if (this.originalSort === '' || this.originalSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_licens:asc'
        this.originalSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_licens:desc'
        this.originalSort = ''
      }
    }
    if (sortBy === 'telephone') {
      if (this.originalSort === '' || this.originalSort !== sortBy) {
        this.currentListSortingOrder = 'user_id.phone:asc'
        this.originalSort = sortBy
      } else {
        this.currentListSortingOrder = 'user_id.phone:desc'
        this.originalSort = ''
      }
    }
    if (sortBy === 'email') {
      if (this.originalSort === '' || this.originalSort !== sortBy) {
        this.currentListSortingOrder = 'user_id.email:asc'
        this.originalSort = sortBy
      } else {
        this.currentListSortingOrder = 'user_id.email:desc'
        this.originalSort = ''
      }
    }
    if (sortBy === 'role') {
      if (this.originalSort === '' || this.originalSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_klubrolle:asc'
        this.originalSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_klubrolle:desc'
        this.originalSort = ''
      }
    }
    this.retrieveClubMembers()
    // CommonFunctions.scrollToTop()
  }

  public findMember () : void {
    this.memberSearchTerm = this.memberSearchInputValue
    this.retrieveClubMembers()
    CommonFunctions.scrollToTop()
  }

  public checkUserPermissions (permission: number) : boolean {
    if (permission === defaultClubManagerGroupId || permission === defaultSuperAdminGroupId) {
      return true
    }

    return false
  }

  public expirationDate (dateString: string | null) : string {
    return (dateString === null ? '01/01/70' : this.toDanishDateString(dateString))
  }

  public convertStringToEnum (clubRole: string) : any {
    const regEx = new RegExp('æ')
    let retVal = clubRole.toLowerCase().replaceAll(' ', '_')
    if (regEx.test(retVal)) {
      retVal = retVal.replace('æ', 'ae')
    }
    const enumValue = stringToEnumValue<typeof ClubRoleType, ClubRoleType>(ClubRoleType, retVal)

    return enumValue
  }

  public convertClubRoleEnumToArray () : void {
    const regEx = new RegExp('ae')
    const tempArray = Object
      .values(ClubRoleType)
      .filter(function (element) { return (<string>element).startsWith((<string>element).substr(0, 1).toLowerCase()) })
      .toString()
      .replaceAll('_', ' ')
      .split(',')
    for (const item of tempArray) {
      if (regEx.test(item)) {
        const temp = item.replace('ae', 'æ')
        this.clubRoleOptions.push(this.capitalize(temp))
      } else {
        this.clubRoleOptions.push(this.capitalize(item))
      }
      // if (!item.trim().startsWith('ae')) {
      // } else {
      //   this.clubRoleOptions.push(this.capitalize(item).replace('Ae', 'Æ'))
      // }
    }
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/').replaceAll('-', '/')
  }

  public extraInfo (memberId: number) : void {
    MembersDataService.get(memberId.toString())
      .then((response) => {
        const tempMember: SysMember = response.data
        // console.log(tempMember)

        this.memberData = {
          memberId: Number(tempMember.id),
          firstName: tempMember.user_id.firstname,
          lastName: tempMember.user_id.lastname,
          userName: tempMember.user_id.username,
          telephone: (tempMember.user_id.phone === null ? '' : Number(tempMember.user_id.phone).toString()),
          email: (tempMember.user_id.email === null ? '' : tempMember.user_id.email),
          address: tempMember.medlem_vejnummer,
          postalNumber: tempMember.bypost_id.bypost_postnummer.toString(),
          city: tempMember.bypost_id.bypost_by,
          paraDartPlayer: tempMember.medlem_paradart,
          licenseExpires: tempMember.medlem_licens_slut,
          licenseNumber: tempMember.medlem_licens,
          quarantineStartDate: tempMember.medlem_karantaene_dato,
          quarantineEndDate: tempMember.medlem_karantaene_slutdato,
          birthDate: tempMember.medlem_foedselsdag,
          gender: tempMember.koen_id.Koen,
          teamCaptain: (tempMember.user_id.usrgroup === defaultClubCaptainGroupId),
          lastLogin: (tempMember.user_id.lastActive === null ? 'Aldrig' : this.toDanishDateString(tempMember.user_id.lastActive)),
          lastMatch: (tempMember.medlem_sidste_kamp === null ? 'Ingen kampe' : this.toDanishDateString(tempMember.medlem_sidste_kamp)),
          club: tempMember.klubber_id.klubber_klubnavn,
          permissions: tempMember.user_id.usrgroup,
          userId: Number(tempMember.user_id.id)
        }

        if (tempMember.medlem_klubrolle === null) {
          this.clubRoleStringValue = this.convertClubRoleEnum(ClubRoleType.Medlem)
        } else {
          this.clubRoleStringValue = this.convertClubRoleEnum(tempMember.medlem_klubrolle)
        }
        // console.log(this.memberData)
        this.retrieveMemberHistory(this.memberData.memberId)
        this.extraMemberInfoModal = true
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveMemberHistory (memberId: number) : void {
    let limit = 5
    if (this.showCompleteHistory) {
      limit = -1
    }
    History.HistoryDataService.getAll('created_at:desc', { slicemode: 0, start: 0, limit: limit, page: 0, pagesize: 0, totalcount: 0 }, `medlem_id.id=${memberId.toString()}`)
      .then((response) => {
        this.memberHistory = response.data
        // console.log(this.memberHistory)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public testActiveQuarantine (startDate: string, endDate: string) : boolean {
    if (startDate !== null && endDate !== null) {
      if (Date.parse(startDate) < Date.parse(this.todaysDate) && Date.parse(endDate) > Date.parse(this.todaysDate)) {
        return true
      }
    }
    return false
  }

  public releaseMember (memberId: number) : any {
    if (memberId === 0) {
      return this.$Message.danger({ text: 'Ups et eller andet gik galt' })
    }

    const tempMember = this.clubMembers.find(element => element.id === memberId)
    if (tempMember === undefined) {
      return this.$Message.danger({ text: 'Ups et eller andet gik galt' })
    }
    const releaseMember = tempMember
    let tempClub = {} as SysClub

    // Runs a check to see if the member is the club manager of the club
    ClubsDataService.get(Number(releaseMember.klubber_id.id).toString())
      .then((response) => {
        tempClub = response.data

        if (tempClub.klubber_ansvarlig_id.id === releaseMember.id) {
          const updateClubManager = {
            klubber_ansvarlig_id: null
          }

          ClubsDataService.update(Number(tempClub.id).toString(), updateClubManager)
            .then((response) => {
              console.log('Changing club manager: ' + response.statusText)
            })
            .catch((err) => {
              console.error(err)
            })
        }
      })
      .catch((err) => {
        console.error(err)
      })

    const updateUserInfo = async (userId: number, updateData: any) => {
      await UsersDataService.update(userId.toString(), updateData)
        .then((response) => {
          console.log('Updating UserInfo: ' + response.statusText)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const updateMemberInfo = async (memberId: number, updateData: any) => {
      await MembersDataService.update(memberId.toString(), updateData)
        .then((response) => {
          console.log('Updating MemberInfo: ' + response.statusText)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const createMemberHistoryEntry = async (createHistoryEntry: any) => {
      await History.HistoryDataService.create(createHistoryEntry)
        .then((response) => {
          console.log('Createing new history entry: ' + response.statusText)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    // Don't know if this necessary
    const createClubChangeEntry = async (createClubChange: any) => {
      await ClubChangeDataService.create(createClubChange)
        .then((response) => {
          console.log('Creating new club change entry: ' + response.statusText)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const promises = []

    const updateUserData = {
      usrgroup: defaultAdminBackendGroupId,
      role: 4
    }
    promises.push(updateUserInfo(Number(releaseMember.user_id.id), updateUserData))
    const updateMemberData = {
      medlem_klubrolle: ClubRoleType.Medlem,
      klubber_id: defaultClubId,
      hold_id: null
    }
    promises.push(updateMemberInfo(Number(releaseMember.id), updateMemberData))

    const createHistoryData = {
      medlemshistorik_handling: 'Klubskifte til Gæsteklubben',
      medlem_id: releaseMember.id,
      klubber_id: defaultClubId
    }

    promises.push(createMemberHistoryEntry(createHistoryData))

    const createClubChangeData = {
      medlem_id: releaseMember.id,
      klubskifte_gmlklub: releaseMember.klubber_id.id,
      klubskifte_nyklub: defaultClubId,
      klubskifte_status: false, // Means that the whole process is automatically approved
      klubskifte_god_afv: SysClubChangeFriendlyType.Godkend
    }
    promises.push(createClubChangeEntry(createClubChangeData))

    Promise.all(promises)
      .then(() => {
        this.releaseMemberModal = false
        this.retrieveClubMembers()
        this.$Message.success({ text: 'Medlemmet er blevet overført til Gæsteklubben' })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public releaseMemberWarning (memberId: number) : void {
    this.releaseMemberId = memberId
    this.releaseMemberModal = true
  }

  public convertClubRoleEnum (enumObj: any) : string {
    enumObj = enumObj.toString().replaceAll('ae', 'æ').replaceAll('_', ' ')
    return this.capitalize(enumObj)
  }

  public capitalize (word: string) : string {
    return word[0].toUpperCase() + word.slice(1)
  }

  public canBecomeCaptain (permissionsId: number) : boolean {
    if (permissionsId === defaultClubManagerGroupId || permissionsId === defaultSuperAdminGroupId) {
      return false
    }

    return true
  }

  public changeTeamCaptainStatus (userId: number, userPrivilegesId : number | null) : void {
    if (userPrivilegesId === defaultClubManagerGroupId || userPrivilegesId === defaultSuperAdminGroupId) {
      // Return if the user already have higher permissions than team captain
      return
    }
    if (userPrivilegesId !== defaultClubCaptainGroupId) {
      // If the user is not a team captain make him one
      const updatePermissions = {
        usrgroup: defaultClubCaptainGroupId,
        role: 3
      }

      UsersDataService.update(userId.toString(), updatePermissions)
        .then((response) => {
          console.log('Giving member team captains permissions: ' + response.statusText)
          this.retrieveClubMembers()
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      // Else make him a ordinary member
      const updatePermissions = {
        usrgroup: defaultAdminBackendGroupId
      }
      UsersDataService.update(userId.toString(), updatePermissions)
        .then((response) => {
          console.log('Removing team captains permissions from member: ' + response.statusText)
          this.retrieveClubMembers()
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  public teamCaptain (userPrivilegesId : number) : boolean {
    if (userPrivilegesId === defaultClubCaptainGroupId) {
      return true
    }
    return false
  }

  public addLicensesRenewalToCart () : void {
    if (!localStorage.getItem('cart')) {
      localStorage.setItem('cart', JSON.stringify([]))
    }

    const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)

    for (const member of this.checked) {
      let newItem = {} as SysCart
      const extraItemData: any = {
        type: 101,
        clubId: this.clubId,
        clubSaldoReduction: 0,
        memberId: Number(member.id),
        memberLicenseEnd: this.licenseEndDate(member.medlem_licens_slut)
      }

      newItem = {
        placeInCart: Number(cartItems.length),
        productName: (this.calculateAge(member.medlem_foedselsdag) <= 17 ? this.juniorLicenseProduct.produkt_navn : this.licenseProduct.produkt_navn),
        productDescription: (this.calculateAge(member.medlem_foedselsdag) <= 17 ? 'Junior l' : 'L') + 'icens fornyelse til: ' + member.user_id.firstname + ' ' + member.user_id.lastname,
        price: this.calcLicensePrice(member.medlem_licens_slut, member.medlem_foedselsdag, this.licenseProduct.produkt_pris, this.juniorLicenseProduct.produkt_pris),
        dateInCart: this.todaysDate,
        expirationDate: null,
        playerRegistration: null,
        productId: this.calculateAge(member.medlem_foedselsdag) <= 17 ? defaultJuniorLicenseId : defaultLicenseId,
        quantity: 1,
        licenseRenewal: { memberID: Number(member.id), newLicenseEndDate: this.licenseEndDate(member.medlem_licens_slut) },
        clubLicenseRenewal: null,
        eventSignUp: null,
        teamRegistration: null,
        competitionFeeData: null,
        orderExtraData: extraItemData
        // orderExtraData: null
      }

      cartItems.push(newItem)
    }
    localStorage.setItem('cart', JSON.stringify(cartItems))
    this.checked = []
    this.renewLicensesModal = false
    this.$router.push('/kassen')
  }

  public licenseEndDate (expirationDate: string | null) : string {
    const currentYear: number = new Date().getFullYear()

    // FIXME: Dato skal hentes korrekt fra produktets startdato.
    if ((expirationDate === null || Date.parse(expirationDate) < Date.parse(this.todaysDate)) && (Date.parse(this.todaysDate) < Date.parse('2023-11-30T00:00:00') || Date.parse(this.todaysDate) >= Date.parse('2024-01-01T00:00:00'))) {
      return (currentYear).toString() + '-12-31'
    }
    return (currentYear + 1).toString() + '-12-31'
  }

  public calcLicensePrice (expirationDate: string | null, birthDate: string, seniorPrice: number, juniorPrice: number) : number {
    const price = (this.calculateAge(birthDate) <= 17 ? juniorPrice : seniorPrice)
    // console.log('[calcLicensePrice()] Dato tjek :' + Date.parse(this.todaysDate) + ' ( ' + this.todaysDate + ' ) / ' + Date.parse(this.seasonHalfWayPoint) + ' ( ' + this.seasonHalfWayPoint + ' )')

    // FIXME: Dato skal hentes korrekt fra produktets startdato.
    // if ((expirationDate === null || Date.parse(this.todaysDate) > Date.parse(expirationDate)) && Date.parse(this.todaysDate) > Date.parse(this.seasonHalfWayPoint) && Date.parse(this.todaysDate) < Date.parse('2023-12-01T00:00:00')) {
    if ((expirationDate === null || Date.parse(this.todaysDate) > Date.parse(expirationDate)) && Date.parse(this.todaysDate) > Date.parse(this.seasonHalfWayPoint) && Date.parse(this.todaysDate) < Date.parse(this.seasonEndPoint)) {
      console.log('[calcLicensePrice()] Half price.')
      return price / 2
    }

    return price
  }

  public renewLicenses () : void {
    if (this.checked.length === 0) {
      return this.$Message.warning({ text: 'Ingen medlemmer valgt' })
    }

    this.renewLicensesModal = true
    const licenseParameter = 'id=' + defaultLicenseId.toString() + '&id=' + defaultJuniorLicenseId.toString()
    let tempLicenses: SysProduct[] = []

    Products.ProductsDataService.getAll('', null, licenseParameter)
      .then((response) => {
        tempLicenses = response.data
        const tempSeniorLicense = tempLicenses.find(element => element.id === defaultLicenseId)
        const tempJuniorLicense = tempLicenses.find(element => element.id === defaultJuniorLicenseId)

        if (tempSeniorLicense !== undefined && tempSeniorLicense !== null && tempJuniorLicense !== undefined && tempJuniorLicense !== null) {
          this.licenseProduct = tempSeniorLicense
          this.juniorLicenseProduct = tempJuniorLicense
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public disableLicenseRenewal (dateString: string | null) : boolean {
    const renewalOpen = new Date(new Date().getFullYear() + '-11-30').toISOString()

    if (dateString === null || Date.parse(this.todaysDate) > Date.parse(dateString) || Date.parse(this.todaysDate) >= Date.parse(renewalOpen)) {
      return false
    }

    return true
  }

  public selectAll () : void {
    if (this.licenseCheckAll) {
      const checked = this.clubMembers.filter(element => { return !this.disableLicenseRenewal(element.medlem_licens_slut) })
      this.checked = checked
      // console.log(this.checked)
    } else {
      this.checked = []
    }
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const managerId = route.params.userSlug.toString().split('-')[0]

    if (Number(managerId) !== undefined) {
      retVal = Number(managerId)
    }

    return retVal
  }

  public editClubMemberMailAddress (userId: number | null) : void {
    console.log('[editClubMemberMailAddress] Changing email for member with user id = ' + Number(userId).toString())
    this.mailUpdateUserId = userId !== null ? userId : 0
    this.editMemberMailAddressModal = true
  }

  public updateMemberMailAddress () : void {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (this.mailUpdateUserId !== null && loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)
      // console.log('[updateMemberMailAddress] usrgroup = ' + Number(userDataObject.usrgroup.id).toString())

      if (userDataObject !== null && Number(userDataObject.id) !== 1 && userDataObject.usrgroup !== undefined && userDataObject.usrgroup.id !== null && (Number(userDataObject.usrgroup.id) === 2 || Number(userDataObject.usrgroup.id) === 3) && this.newMemberMailAddress !== null && this.newMemberMailAddress.toString().trim().length > 5 && this.newMemberMailAddress.toString().trim().includes('@')) {
        const updateMailAdress = {
          email: this.newMemberMailAddress.toString().trim()
        }

        UsersDataService.update(Number(this.mailUpdateUserId).toString(), updateMailAdress)
          .then((response) => {
            console.log('Updated email address of the club member: ' + response.statusText)
            this.editMemberMailAddressModal = false
            this.retrieveClubMembers()
          })
          .catch((err) => {
            console.error(err)
            this.editMemberMailAddressModal = false
          })
      }
    }
  }

  public retrieveClubMembers () : void {
    let manager: SysMember[] = []
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }

    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        manager = response.data

        if (manager.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })
          return
        }
        // A rudementary check, if the memberId from the URL, does not match the memberId retrieved via the userId, logout and go to login page
        if (this.clubManagerId !== Number(manager[0].id)) {
          AuthService.logout()
          CommonFunctions.redirectLogin()
          return
        }

        if (manager[0].user_id.usrgroup !== defaultClubManagerGroupId) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
          AuthService.logout()
          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }
        this.clubId = Number(manager[0].klubber_id.id)
        if (this.memberSearchTerm.length > 0) {
          MembersDataService.findByNameAndLicenseSearchTerm(this.memberSearchTerm, this.currentListSortingOrder, null, `klubber_id.id=${this.clubId.toString()}`)
            .then((response) => {
              this.clubMembers = response.data
            })
            .catch((err) => {
              console.error(err)
            })
        } else {
          MembersDataService.getAll(this.currentListSortingOrder, null, `klubber_id.id=${this.clubId.toString()}`)
            .then((response) => {
              this.clubMembers = response.data
              // console.log(this.clubMembers)
            })
            .catch((err) => {
              console.error(err)
            })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public calculateAge (birthDate: string) : number {
    const ageDifMs = Date.parse(this.todaysDate) - new Date(birthDate).getTime()
    const ageDate = new Date(ageDifMs)

    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  public retrieveDateTimeFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.todaysDate = '2023-11-30T08:00:30'
        // this.todaysDate = response.data.split('T')[0]
        // console.log(Date.parse(this.todaysDate))

        this.seasonHalfWayPoint = new Date(this.todaysDate).getFullYear() + '-07-01'
        this.seasonEndPoint = new Date(this.todaysDate).getFullYear() + '-12-01'
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.retrieveClubMembers()
      this.retrieveDateTimeFromServer()
      this.convertClubRoleEnumToArray()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
