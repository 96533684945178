
import { Options, Vue } from 'vue-class-component'
import clubMembers from '@/components/Memberportal/ClubMembers/index.vue'

@Options({
  components: {
    clubMembers
  }
})
export default class ClubMembers extends Vue {}
